import React from 'react';
import { Input as BaseInput, SIZE } from 'baseui/input';
import useLocalStorage from 'use-local-storage';


const Input = ({ ...props }) => {
  const[theme,setTheme]=useLocalStorage('theme','light')
  const getInputFontStyle = ({ $theme }) => {
    return {
      color: theme==='dark'?'rgb(255 255 255)':$theme.colors.textDark,
      backgroundColor:theme==='dark'?'rgb(40 40 40)':'rgb(238 238 238)',
      ...$theme.typography.fontBold14,
    };
  };

  return (
    <BaseInput
      overrides={{
        Input: {
          style: ({ $theme }) => {
            return {
              ...getInputFontStyle({ $theme }),
            };
          },
        }, InputContainer: {
          style: () => ({
            backgroundColor: theme==='dark'?'rgb(40 40 40)':'rgb(238 238 238)'
          })
        },
        Root: {
          style: () => ({
            backgroundColor: theme==='dark'?'rgb(40 40 40)':'rgb(238 238 238)'
          })
        },
        InputContainer: {
          style: () => ({
            backgroundColor: theme==='dark'?'rgb(40 40 40)':'rgb(238 238 238)'
          })
        },
        EndEnhancer: {
          style: () => ({
            backgroundColor: theme==='dark'?'rgb(40 40 40)':'rgb(238 238 238)'
          })
        },
      }}
      {...props}
    />
  );
};

export { SIZE };
export default Input;

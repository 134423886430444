import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter  } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { theme } from './theme';
import Routes from './routes';
import './theme/global.css';
import { SWRConfig } from "swr";
import 'animate.css';
import 'react-notifications-component/dist/theme.css';
import { ReactNotifications } from 'react-notifications-component';
import { messages } from './settings/site-translation/messages';
import { LanguageProvider } from './context/language/language.provider';

function App() {
  const engine = new Styletron();
  const MessengerRef = useRef();

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./background-worker.js')
      .then((registration) => {
        console.log('Background Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Background Service Worker registration failed:', error);
      });
    
  }



  return (
    <SWRConfig value={{ refreshInterval: 10000 }}>
      <LanguageProvider messages={messages}>
        <StyletronProvider value={engine}>
          <BaseProvider theme={theme}>
            <BrowserRouter>
              <ReactNotifications />
              <Routes />
            </BrowserRouter>
          </BaseProvider>
        </StyletronProvider>
      </LanguageProvider>
    </SWRConfig>
  );
}

ReactDOM.render(<App />, document.getElementById('my_react_app'));

// Temporarily unregister the service worker for testing

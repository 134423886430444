

import React, { useState } from 'react';
import { styled, withStyle,createThemedUseStyletron } from 'baseui';
import {
  Grid,
  Row as Rows,
  Col as Column,
} from '../../components/FlexBox/FlexBox';
import { Wrapper, Header, Heading } from '../../components/WrapperStyle';

import {Button, KIND} from 'baseui/button';
import { injectIntl } from 'react-intl';




import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import {
  Label1,
  Label2,
  Label3,
  Label4,
  Paragraph1,
  ParagraphMedium,
  ParagraphSmall,
  ParagraphXSmall,
  H1, H4 , H5 , H6
} from 'baseui/typography';
import { SubTitle, Title, TitleWrapper } from '../../components/FileCard/StickerCard.style';
require('dayjs/locale/ar');
require('dayjs/locale/fr');
require('dayjs/locale/en');

type CustomThemeT = { red400: string; textNormal: string; colors: any };

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}));

const Image = styled('img', () => ({
  width: '90%',
  height: 'auto',
}));






 const  DtcManual = ({intl}) => {
 const history = useHistory();



  return (
    <Grid fluid={true}>
          <Breadcrumbs>
      <StyledLink href="/help"><FormattedMessage id = 'Help'/></StyledLink>
      <span><FormattedMessage id = 'Pinout'/></span>
    </Breadcrumbs>
 
  

    


    </Grid>
  );
}; 
export default injectIntl (DtcManual);


export const defaultLocale = 'en' as const;

export const locales = ['en', 'ar', 'fr'] as const;
export const rtlLocales = ['ar', 'he'] as const;

// need to customize later
export const languageNames = {
  en: 'English',
  fr: 'Frensh',
  ar: 'Arabic',
};

import React from 'react';
import BoxWrapper, {
  BoxHeading,
  TitleWrapper,
  BoxContentWrapper,
} from './Box.style';
import useLocalStorage from 'use-local-storage';
type BoxTitleProps = {
  title: string;
};

type BoxProps = {
  children: React.ReactNode;
  style?: any;
};

export const BoxTitleWrapper: React.FC<BoxProps> = ({ children, style }) => {
  
  return <TitleWrapper style={style}>{children}</TitleWrapper>;
};

export const BoxTitle: React.FC<BoxTitleProps> = ({ title }) => {
  const [theme,setTheme]=useLocalStorage('theme','light')
  return <BoxHeading style={{
  }}>{title}</BoxHeading>;
};

export const Box = ({ children }: any) => {
  const [theme,setTheme]=useLocalStorage('theme','light')
  return <BoxWrapper
  style={{
  
    backgroundColor:theme==='dark'?'rgb(22 22 22)':'',
    boxShadow:theme==='dark'?'0 21px 36px rgba(255, 255, 255, 0.06)':''
  }}
  >{children}</BoxWrapper>;
};

export const BoxContent: React.FC<BoxProps> = ({ children, style }) => {
  return <BoxContentWrapper style={style}>{children}</BoxContentWrapper>;
};

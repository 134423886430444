import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ApiUsers, ApiFiles, ApiDownload, ApiNotifications, ApiOrders, ApiTickets, ApiTransactions, ApiUpload, ApiVehicles, ApiDashboard,
  ApiCredits, ApiDtc, ApiWarningMessages, ApiEcuNumbers, ApiSettings, ApiCouponCodes, ApiEcuNotes, ApiPopup, ApiMails, ApiFilesToDownload,
  ApiDfCodes, ApiSolutionsFormula, ApiSolutions, ApiSolutionCategorys
} from '../services';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
  user: {
    authorization: string,
    role: string,
    settings: {},
    userInfo: {
      email: string,
      name: string,
      surname: string,
      photo: string,
      masterCredit: number,
      slaveCredit: number
    }
  };
  saveUser: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const setAxiosHeaders = (token) => {
  const Apis = [
    ApiSolutionCategorys, ApiSolutions, ApiSolutionsFormula, ApiUsers, ApiFiles, ApiDownload, ApiNotifications, ApiOrders, ApiTickets,
    ApiTransactions, ApiUpload, ApiVehicles, ApiDashboard, ApiCredits, ApiDtc, ApiDfCodes, ApiWarningMessages, ApiEcuNumbers,
    ApiSettings, ApiCouponCodes, ApiEcuNotes, ApiPopup, ApiMails, ApiFilesToDownload
  ];

  Apis.forEach(api => {
    api.interceptors.request.use(function (config) {
      config.headers = { 'authorization': token };
      return config;
    });
  });
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [user, setUser] = useState({
    authorization: '',
    role: '',
    settings: {},
    userInfo: {
      email: '',
      name: '',
      surname: '',
      photo: '',
      masterCredit: 0,
      slaveCredit: 0
    }
  });

  const history: any = useHistory();

  const saveUser = user => {
    const newUser = {
      authorization: user.data.token,
      role: user.data.data.user.role,
      settings: user.data.data.settings,
      userInfo: {
        email: user.data.data.user.email,
        name: user.data.data.user.name,
        surname: user.data.data.user.surname,
        photo: user.data.data.user.photo,
        masterCredit: user.data.data.user.masterCredit,
        slaveCredit: user.data.data.user.slaveCredit
      }
    };
    setUser(newUser);
  };

  useEffect(() => {
    const token = localStorage.getItem('authorization');
    const intendedRoute = window.location.pathname;

    if (token) {
      ApiUsers.post('/logged', null, { headers: { authorization: token } })
        .then((res) => {
          makeAuthenticated(true);
          saveUser(res);
          setAxiosHeaders(res.data.data.token);
          history.push(intendedRoute);
        })
        .catch(() => makeAuthenticated(false))
        .finally(() => setLoading(false)); // Stop loading after the process
    } else {
      setLoading(false); // Stop loading if there's no token
    }
  }, []);

  async function authenticate({ email, password, setTestLogin, tokenFirebaseDesktop }) {
    try {
      const res = await ApiUsers.post('/login', { email, password, tokenFirebaseDesktop });
      localStorage.setItem('authorization', res.data.token);
      makeAuthenticated(true);
      saveUser(res);
      setAxiosHeaders(res.data.token);
      history.push('/');
    } catch (e) {
      setTestLogin(true);
    }
  }

  async function signout(cb) {
    makeAuthenticated(false);
    await ApiUsers.get('/logout');
    localStorage.removeItem("authorization");
    localStorage.removeItem("theme");
    setTimeout(cb, 100);
  }

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while loading
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        user,
        saveUser
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React, { useState } from 'react';
import { withStyle } from 'baseui';

import { styled } from "baseui";


import {
  Grid,
  Row as Rows,
  Col as Column,
} from '../../components/FlexBox/FlexBox';
import {
  Card,
  TopInfo,
  TitleWrapper,
  SubTitle
} from '../../components/FileCard/StickerCard.style';



import {  ApiVehicles } from '../../services';
import {Avatar} from 'baseui/avatar';
import { FormattedMessage , injectIntl } from 'react-intl';
import $ from "jquery";

import ColumnChartTuneMyRide from '../../components/Widgets/ColumnChart/ColumnChartTuneMyRide';

export const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px) and (max-width: 991px)': {
    alignItems: 'center',
  },
}));




 const Title = styled("span", ({ $theme }) => ({
  ...$theme.typography.fontBold16,
  fontFamily: $theme.typography.primaryFontFamily,
  lineHeight: "1.2",
  color: 'black',
  marginBottom: "10px"
}));




const Tuning = ({intl}) => {
  

  const types = [
    { value: 'car', name: 'car', id: '1' , type : 'car'  },
    { value: 'Truck', name: 'Truck', id: '2' },
  ];

  // state of select vehicle info type,brand,model ect ... 
  const [type, setType] = useState([]);
  const [marque, setMarque] = useState([]);
  const [model, setModel] = useState([]);
  const [vehicleYear, setVehicleYear] = useState([]);
  const [engine, setEngine] = useState([]);

  const [show , setShow] = useState(false);


  const [state,setState] = useState ({
    vehicleTypes : [],
    marques : [], 
    models : [],
    vehicleYears : [],
    engines : [] ,
    ecuMarques : [] , 
    ecuModels : [] ,
    vehicleStats : {
      _id : String ,
      Type : String , 
      Photo : String ,
      Marque : String , 
      Model : String ,
      VehicleYear : String , 
      Engine : String , 
      EcuMarque : String , 
      EcuModel : String , 
      CylinderContent : String , 
      EngineNubmer : String , 
      Stage: String ,
      MotorFuel: String, 
      StandardPower: Number ,
      StandardTorque: Number ,
      TunedPower: Number,
      TunedTorque : Number 
    }
  }) ;


  
  React.useEffect(() => {
    function iframeResize() {
      var height = $('body').outerHeight(); // IMPORTANT: If body's height is set to 100% with CSS this will not work.
      
      window.parent.postMessage("resize::"+height,"*");
    }
    $(document).ready(function() {
      // Resize iframe
      setInterval(iframeResize, 1000);
    });
    }, []);
  




  const handleTypeChange = async ( e ) => {
    var value = e.target.value ;  
    const res = await ApiVehicles.post('/marques',{ type : value});
    let marques = res.data.data.marques ; 
    setState({...state ,marques,models: [],vehicleYears:[],engines:[]});
    setType(value);
};



   const handleBrandChange = async (e ) => {
    const value = e.target.value ; 
 
      const res = await ApiVehicles.post('/models',{type : type ,marque : value});
      let models = res.data.data.models ; 

      setState({...state , models , vehicleYears : [] , engines : []});      
      setMarque(value); 
   };


    const handleModelChange = async (e) => {
       const value = e.target.value ; 

        const res = await ApiVehicles.post('/vehicleYears',{type,marque,model : value});
        let vehicleYears = res.data.data.vehicleYears ; 
        setState({...state , vehicleYears , engines : [] });
        setModel(value);
      };



      const handleVehicleYearChange = async (e) => {
            const value = e.target.value ; 
     
            const res = await ApiVehicles.post('/engines',{type ,marque ,model ,vehicleYear : value});
            let engines = res.data.data.engines ; 
            setState({...state , engines });
          
            setVehicleYear(value);
        };

      const handleEnginesChange = async (e) => {
        setEngine(e.target.value);
        };

  
    
    const handleClickVeiwGains = async() =>
    {
  
          const res = await ApiVehicles.post('/vehicle',{type ,marque,model ,vehicleYear ,engine });
          let vehicleStats = res.data.data.vehicleStats ; 
          setState({...state , vehicleStats });
          setShow(true);
    } 






  return (
    <Grid fluid={true} style={{backgroundColor: '#f4f4f4' , paddingBottom : '20px'}}>


<div style = {{background: '#f4f4f4', boxSizing: 'border-box',padding: '16px 0' , margin : '0px'}}>
<Row style ={{boxSizing: 'border-box', margin: '0 auto', maxWidth: '1200px', padding: '0 32px',width : '100%'}} >
  <Col lg = {2} md = {2} sm = {2}>
  <select style = {{  display: 'inline-block' ,color : '#222' , height: '50px' , padding:'0 20px' , fontSize:'14px' , lineHeight : '48px' , verticalAlign : 'top' , textTransform : 'inherit' , border:'1px solid #eee' , borderRadius : '0' , backgroundColor : '#fff' , width : '100%' , paddingRight : '45px' , zIndex : 1 ,fontFamily:'revert' }} onChange={(e) => handleTypeChange(e)}>
   <option style = {{paddingBottom : '8px' , paddingTop : '8px'}}>{intl.formatMessage({id : 'Type'})}  </option>
    {types.map((t)=> {
      return <option value= {t.value}>{t.name}</option>
    })}
  </select>
  </Col>
  <Col lg = {2} md = {2} sm = {2}>
  <select style = {{  display: 'inline-block' ,color : '#222' , height: '50px' , padding:'0 20px' , fontSize:'14px' , lineHeight : '48px' , verticalAlign : 'top' , textTransform : 'inherit' , border:'1px solid #eee' , borderRadius : '0' , backgroundColor : '#fff' , width : '100%' , paddingRight : '45px' , zIndex : 1 ,fontFamily:'revert' }}    onChange={(e) => handleBrandChange(e)} >
    <option >{intl.formatMessage({id : 'Brand'})}   </option>
    {state.marques.map((t)=> {
      return <option value = {t.value}                  
      >{t.name}</option>
    })}
  </select>
  </Col>
  <Col lg = {3} md = {3} sm = {3}>
  <select style = {{  display: 'inline-block' ,color : '#222' , height: '50px' , padding:'0 20px' , fontSize:'14px' , lineHeight : '48px' , verticalAlign : 'top' , textTransform : 'inherit' , border:'1px solid #eee' , borderRadius : '0' , backgroundColor : '#fff' , width : '100%' , paddingRight : '45px' , zIndex : 1 ,fontFamily:'revert' }}  onChange={(e) => handleModelChange(e)} >
    <option >{intl.formatMessage({id : 'Model'})} </option>
    {state.models.map((t)=> {
      return <option value = {t.value}>{t.name}</option>
    })}
  </select>
  </Col>
  <Col lg = {2} md = {2} sm = {2}>
  <select style = {{  display: 'inline-block' ,color : '#222' , height: '50px' , padding:'0 20px' , fontSize:'14px' , lineHeight : '48px' , verticalAlign : 'top' , textTransform : 'inherit' , border:'1px solid #eee' , borderRadius : '0' , backgroundColor : '#fff' , width : '100%' , paddingRight : '45px' , zIndex : 1 ,fontFamily:'revert' }}    onChange={(e) => handleVehicleYearChange(e)}  >
    <option >{intl.formatMessage({id : 'Year'})} </option>
    {state.vehicleYears.map((t)=> {
      return <option value = {t.value}>{t.name}</option>
    })}
  </select>
</Col>
<Col lg = {3} md = {3} sm = {3}>
  <select  onChange={(e) => handleEnginesChange(e)} style = {{  display: 'inline-block' ,color : '#222' , height: '50px' , padding:'0 20px' , fontSize:'14px' , lineHeight : '48px' , verticalAlign : 'top' , textTransform : 'inherit' , border:'1px solid #eee' , borderRadius : '0' , backgroundColor : '#fff' , width : '100%' , paddingRight : '45px' , zIndex : 1 ,fontFamily:'revert' }}   >
    <option>{intl.formatMessage({id : 'Engine'})}  </option>
    {state.engines.map((t)=> {
      return <option value = {t.value} >{t.name}</option>
    })}
  </select>
  </Col>
  <Col lg = {12} md = {12} sm = {12}>
<button style={{marginTop : '40px' , width:'100%' , backgroundColor : '#d01818' ,  display : 'inline-block' , height : '44px' , marginBottom : '0' , padding : '0 20px' , font : '600 12px/40px Poppins' , textAlign : 'center' , verticalAlign : 'middle' , border : '2px solid transparent' , color : 'white' , borderRadius : '2px' , textTransform : 'uppercase' , cursor : 'pointer' }}  onClick = {handleClickVeiwGains} ><FormattedMessage id ="ViewGains"/></button>
</Col>
</Row>
</div>
              
              <br></br>
                
               
{show && 

<React.Fragment>
      <Row>
    <Card>
      <TopInfo>
        <TitleWrapper>
          <Title style={{color : 'black'}}>      {state.vehicleStats.Marque} {state.vehicleStats.Model} ({state.vehicleStats.VehicleYear}) {state.vehicleStats.Engine} </Title>




          <br/>
          <Row>
    <Col>
    <SubTitle><FormattedMessage id="MotorFuel"/></SubTitle>
    </Col>
    <Col>
    <Title>
      {state.vehicleStats.MotorFuel} 
    </Title>
    </Col>
    <Col>
    <SubTitle><FormattedMessage id="StandardPower"/></SubTitle>
    </Col>
    <Col>
    <Title>
      {state.vehicleStats.StandardPower} Hp 
    </Title>
    </Col>
    <Col>
    <SubTitle><FormattedMessage id="StandardTorque"/></SubTitle>
    </Col>
    <Col>
    <Title>
      {state.vehicleStats.StandardTorque} Nm
    </Title>
    </Col>
  </Row>

  <Row>
    <Col>
    <SubTitle><FormattedMessage id="CylinderCapacity"/></SubTitle>
    </Col>
    <Col>
    <Title>
      {state.vehicleStats.CylinderContent} 
    </Title>
    </Col>
    <Col>
    <SubTitle><FormattedMessage id="EngineCode"/></SubTitle>
    </Col>
    <Col>
    <Title>
      {state.vehicleStats.EngineNubmer} 
    </Title>
    </Col>
    <Col>
    <SubTitle><FormattedMessage id="TypeEcu"/></SubTitle>
    </Col>
    <Col>
    <Title>
      {state.vehicleStats.EcuMarque} / {state.vehicleStats.EcuModel} 
    </Title>
    </Col>
  </Row>


        </TitleWrapper>
        <Avatar
          name={`user`}
          size= '150px'
          src={`${process.env.REACT_APP_API_URL}/img/imageMarque/${state.vehicleStats.Photo}`}
          key='so'
        />
        
      </TopInfo>
   


    </Card>
    
    </Row>
    <Row>
       {/* <Col md={4} lg={4}>
          <DonutChart
            widgetTitle='Target'
            series={[state.vehicleStats.StandardPower, state.vehicleStats.TunedPower ]}
            labels={['Standard Power' , 'Tuned Power']}
            colors={['#03D3B5', '#FC747A']}
            helperText={['Standard power' , 'Tuned Power']}
            icon={[<Revenue />, <Refund />]}
            prefix='$'
          />
        </Col> */}
        <Col md={6} lg={6} >
        <ColumnChartTuneMyRide
            widgetTitle={intl.formatMessage({id : "Power"})+' Hp'}
            colors={['rgb(208, 24, 24)','rgb(229, 229, 229)']}
            // prefix='Hp'
            totalValue={' +'+state.vehicleStats.TunedPower?.toString()+' Hp'}
            position='up'
            percentage={((((Number(state.vehicleStats.TunedPower))) / (Number(state.vehicleStats.StandardPower)))*100).toFixed(2)+'%' }
            text='More than Standard hp'
            series={state.vehicleStats.StandardPower ?[state.vehicleStats.StandardPower?.toString(),(Number(state.vehicleStats.StandardPower) + Number(state.vehicleStats.TunedPower))?.toString() ] : [' ',' ']}
            categories={['Standard','Stage 1']}
          />

          {/* <RadialBarChart
            widgetTitle={intl.formatMessage({id : "Power"})+' Hp'}
            series={state.vehicleStats.StandardPower ? [state.vehicleStats.StandardPower.toString() , state.vehicleStats.TunedPower.toString() ] : [' ',' ']}
            label={ state.vehicleStats.StandardPower ?  [state.vehicleStats.StandardPower.toString()+'Hp' , '+'+state.vehicleStats.TunedPower.toString()+'Hp' ] : [' ',' ']}
            colors={['#03D3B5','#FC747A', 'rgb(33, 112, 255)','#FC747A','rgb(102, 109, 146)']}
            helperText={[intl.formatMessage({id : "StandardPower"}) , intl.formatMessage({id : "TunedPower"})]}
          /> */}
        </Col>
        <Col md={6} lg={6} >
        <ColumnChartTuneMyRide
            widgetTitle={intl.formatMessage({id : "Torque"})+' Nm'}
            colors={['rgb(146, 143, 143)','rgb(208, 24, 24)']}
            // prefix='Hp'
            totalValue={' +'+state.vehicleStats.TunedTorque?.toString()+' Nm'}
            position='up'
            percentage={((((Number(state.vehicleStats.TunedTorque))) / (Number(state.vehicleStats.StandardTorque)))*100).toFixed(2)+'%' }
            text='More than Standard Nm'
            series={state.vehicleStats.StandardTorque ?[state.vehicleStats.StandardTorque?.toString(),(Number(state.vehicleStats.StandardTorque) + Number(state.vehicleStats.TunedTorque))?.toString() ] : [' ',' ']}
            categories={['Standard','Stage 1']}
          />

          {/* <RadialBarChart
            widgetTitle={intl.formatMessage({id : "Torque"})+' Nm'}
            series={state.vehicleStats.StandardTorque ? [state.vehicleStats.StandardTorque.toString() , state.vehicleStats.TunedTorque.toString() ] : [' ',' ']}
            label={state.vehicleStats.StandardTorque ? [state.vehicleStats.StandardTorque.toString()+'Nm' , '+'+state.vehicleStats.TunedTorque.toString()+'Nm' ] : [' ',' ']}
            colors={['#03D3B5','#FC747A', 'rgb(33, 112, 255)','#FC747A','rgb(102, 109, 146)']}
            helperText={[intl.formatMessage({id : "StandardTorque"}) , intl.formatMessage({id : "TunedTorque"})]}
          /> */}
        </Col>

    </Row>
    </React.Fragment>
}
    </Grid>
  );
}

export default injectIntl(Tuning)
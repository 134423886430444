import localEn from './lang/en.json';
import localAr from './lang/ar.json';
import localFr from './lang/fr.json';


export const messages = {
  en: localEn,
  fr: localFr,
  ar: localAr,
};

import * as React from 'react';
import useLocalStorage from "use-local-storage";
import { Select } from 'baseui/select';
import { CaretDownIcon } from '../../assets/icons/CaretDownIcon';


export const getContainerFontStyle = ({ $theme }) => {
  
  return $theme.typography.fontBold14;
};

export default ({ ...props }) => {
  const [them,setTheme]=useLocalStorage('theme','light')
  const backgroundTheme={backgroundColor:them==='dark'?'rgb( 40 40 40)':'rgb(238 238 238)'}
  const textTheme={color:them==='dark'?'rgb(255 255 255)':''}


  return (
    <Select
      
      overrides={{
        
        
        SelectArrow: () => {
          return <CaretDownIcon />;
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: { zIndex: 1 },
              },
            },
          },
        },
        ControlContainer: {
          style: () => (backgroundTheme)
        },
        Input: {
          style: () => (textTheme)
        },
        Dropdown: {
          style: () => (backgroundTheme)
        },
        Placeholder: {
          style: ({ $theme }) => ({
            color:them==='dark'?'rgb(118 118 118)':$theme.colors.textDark,
            ...getContainerFontStyle({ $theme }),
          }),
        },
        SingleValue: {
          style: ({ $theme }) => ({
            ...getContainerFontStyle({ $theme }),
            color:them==='dark'?'#ffffff': $theme.colors.textDark,
            lineHeight: '1.5',
          }),
        },
        DropdownListItem: {
          style: ({ $theme }) => ({
            fontSize: '14px',
            fontWeight: '700',
            color:them==='dark'?'rgb(40 40 40)':'#ffffff',
           
          }),
        },
        OptionContent: {
          style: ({ $theme, $selected }) => {
            return {
              ...$theme.typography.fontBold14,
              color: $selected
                ? $theme.colors.textDark
                : $theme.colors.textNormal,
            };
          },
        },
        DropdownOption: {
          style: ({ $theme }) => ({
            fontSize: '14px',
            fontWeight: '700',
            color: $theme.colors.textDark,
          }),
        },
        
      }}
      {...props}
    />
  );
};
